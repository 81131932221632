export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'betTool',
    label: 'field.tool',
  },
  {
    key: 'time',
    label: 'field.time',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
