export default [
    {
        key: 'betToolId',
        label: 'field.tool',
        type: 'nAsynSingleSelection',
        repository: 'tool',
        selectionKey: 'id',
        selectionLabel: 'betToolEn',
        cols: 4
    },
];